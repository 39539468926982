import React, { useContext, useEffect, useState } from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import { PageWrap } from "../../components/common/Pages";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Dropdown } from "../../components/common/Dropdown";
import { Button } from "@material-ui/core";
import styled from "styled-components";
import { SimpleFileUploader } from "../../components/common/SimpleUploaderFile";
import { handleFileChange } from "../../components/common/FileUploaderHelpers";
import { FileReason } from "../../../../store/generated-models";
import { useSnackbar } from "notistack";
import { RestApiContext } from "../../../../core/providers/RestApiProvider";
import { AuthContext } from "../../../../core/providers/AuthProvider";
import {withTranslation} from "react-i18next";
import {t} from "i18next";

const Row = styled.div`
  display: flex;
`;

const StyledTextField = styled(TextField)`
  background-color: white;
  .MuiOutlinedInput-root {
    :hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: #9e9e9e;
      }
    }
  }
`;

const msgTypes = [
  { id: "BankWithdrawal", value: "Bank Withdrawal" },
  { id: "BitcoinWithdrawal", value: "Bitcoin Withdrawal" },
  { id: "PRWithdrawal", value: "XPR Withdrawal" },
  { id: "LTCWithdrawal", value: "LTC Withdrawal" },
  { id: "ETHWithdrawal", value: "ETH Withdrawal" },
  { id: "BCHWithdrawal", value: "BCH Withdrawal" },
  { id: "BankDeposit", value: "Bank Deposit" },
  { id: "BitcoinDeposit", value: "Bitcoin Deposit" },
  { id: "XRPDeposit", value: "XRP Deposit" },
  { id: "LTCDeposit", value: "LTC Deposit" },
  { id: "ETHDeposit", value: "ETH Deposit" },
  { id: "BCHDeposit", value: "BCH Deposit " },
  { id: "Verification", value: "Verification" },
  {
    id: "CorporateBusinessVerification",
    value: "Corporate/Business Verification",
  },
  { id: "KnowYourCustomerProcedure", value: "Know Your Customer Procedure" },
  { id: "HWWallet", value: "HW Wallet" },
  { id: "Other", value: "Other" },
];

const initFields = {
  msgType: "",
  message: "",
};

const Support: React.FC<RouteComponentProps> = () => {
  const { enqueueSnackbar } = useSnackbar();
  const restContext = useContext(RestApiContext);
  const authContext = useContext(AuthContext);

  const [fields, setFields] = useState<any>({ ...initFields });
  const [errors, setErrors] = useState<any>({});
  const [file, setFile] = useState<any>(null);
  const [fileErr, setFileErr] = useState("");
  const fileRef = React.useRef(null);

  useEffect(() => {
    if (authContext.menuItems.findIndex((el) => el.label === "home") === -1) {
      navigate("/home/main").then();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const upload = () => {
    let formData = new FormData();
    formData.append("text", fields.message);
    formData.append("type", fields.msgType);
    formData.append("reason", FileReason.Support);
    formData.append("file", file.info);
    formData.append("fileData", JSON.stringify({ type: FileReason.Support }));
    return restContext.upload("rest/api/addSupportCase", formData);
  };

  const sendMessageHandler = async () => {
    let err: any = {};
    if (!fields.msgType) err.msgType = "required";
    if (!fields.message) err.message = "required";
    setErrors({ ...err });

    if (Object.keys(err).length !== 0) {
      enqueueSnackbar("Correct errors to continue", { variant: "error" });
      return;
    }

    let errMessage = "";
    try {
      const uploadRes = await upload();
      if (uploadRes && uploadRes.status && uploadRes.status === 200) {
        setFields({ ...initFields });
        setFile(null);
        setFileErr("");
        fileRef.current.clear();
        enqueueSnackbar("Message sent", { variant: "success" });
        return;
      } else {
        errMessage = "Process error. Please try again later";
      }
    } catch (error: any) {
      try {
        const errorCode = error.graphQLErrors[0].extensions.code;
        if (errorCode === "auth.access_denied") errMessage = "Access denied";
        if (!errMessage && error.graphQLErrors[0].message)
          errMessage = error.graphQLErrors[0].message;
      } catch (ignored) {}
    }

    if (!errMessage) errMessage = "Unknown error";
    enqueueSnackbar(errMessage, { variant: "error" });
  };

  return (
    <PageWrap>
      <Grid container spacing={3} style={{ maxWidth: "700px" }}>
        <Grid item xs={12}>
          <Dropdown
            error={!!errors.msgType}
            helperText={errors.msgType}
            label={t('support.theme')}
            fullWidth
            values={msgTypes}
            value={fields.msgType}
            onChange={(val) => setFields({ ...fields, msgType: val })}
            style={{ backgroundColor: "white" }}
          />
        </Grid>
        <Grid item xs={12}>
          <StyledTextField
            error={!!errors.message}
            helperText={errors.message}
            variant="outlined"
            fullWidth
            multiline
            minRows={4}
          	maxRows={6}
            label={t('support.your_message')}
            value={fields.message}
            style={{ backgroundColor: "white" }}
            onChange={(e) => setFields({ ...fields, message: e.target.value })}
          />
        </Grid>
        <Grid item xs={12}>
          <SimpleFileUploader
            ref={fileRef}
            errText={fileErr}
            variant={"outlined"}
            label={t('support.browse_file')}
            onChange={(e) =>
              handleFileChange(e, setFile, setFileErr, {
                maxSize: 5 * 1024 * 1024,
              })
            }
          />
        </Grid>
      </Grid>
      <Row style={{ marginTop: "24px" }}>
        <Button
          variant="contained"
          color="primary"
          size={"large"}
          style={{ minWidth: "240px", minHeight: "50px" }}
          onClick={sendMessageHandler}
          // disabled={addSupportCaseLoading}
        >
          {t('support.send_to_support')}
        </Button>
      </Row>
    </PageWrap>
  );
};

export default withTranslation()(Support)
