import React, {useContext, useEffect, useState} from 'react';
import {RouteComponentProps} from "@reach/router";
import styled from 'styled-components';
import {PageWrap} from '../../components/common/Pages';
import Typography from "@material-ui/core/Typography";
import {SimpleFileUploader} from "../../components/common/SimpleUploaderFile";
import {handleFileChange} from "../../components/common/FileUploaderHelpers";
import {Button} from "@material-ui/core";
import {Grid, HeadCell} from "../../../../core/ui/grids/Grid";
import {
	FileReason,
	MutationDeleteFileArgs
} from "../../../../store/generated-models";
import {gql} from "@apollo/client";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {formatDate} from "../../helpers/Format";
import {RestApiContext} from "../../../../core/providers/RestApiProvider";
import {useSnackbar} from "notistack";

const ITEM_PER_PAGE: number = 20;

const Row = styled.div`
  display: flex;
`;

const UploadRow = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

const headCells: HeadCell[] = [
	{id: 'date', label: 'Date'},
	{id: 'name', label: 'Name'},
	{id: 'type', label: 'Type'},
	{id: 'size', label: 'Size', align: 'right'},
];

const gqlGetStatements = gql`
    query GetStatements ($first: Int, $skip: Int, $filter: String) {
        getStatements (
            first: $first,
            orderBy: "created",
            desc: true,
            skip: $skip,
            filter: $filter
        ) {
            count
            statements {
                created
                fileId
                doesFileExist
                fileName
                originFileName
                originFileNameWithoutExtension
                originExtension
                mimeType
                encoding
                fileSize
                linkedId
                order
                url		
						}
        }
    }`;

// const gqlUploadFile = gql`
//     mutation uploadFile (
//         $uploadParams: UploadParams!
//     ) {
//         uploadFile (
//             uploadParams: $uploadParams,
//         ) {
//             created
//             doesFileExist
//             encoding
//             fileId
//             fileName
//             fileSize
//             linkedId
//             mimeType
//             order
//             originExtension
//             originFileName
//             originFileNameWithoutExtension
//             url
//         }
//     }
// `;

const gqlDeleteFile = gql`
    mutation deleteFile (
        $fileId: String!
    ) {
        deleteFile (
            fileId: $fileId,
        )
    }
`;

export const DailyStatements: React.FC<RouteComponentProps> = () => {

	const {enqueueSnackbar} = useSnackbar();
	const restContext = useContext(RestApiContext);

	const [file, setFile] = useState<any>(null);
	const [fileErr, setFileErr] = useState('');
	const [page, setPage] = useState(1);
	const [pageCnt, setPageCnt] = useState(0);
	const [items, setItems] = useState<any[]>([]);
	const [filter, setFilter] = useState('');

	const fileRef = React.useRef(null);

	const {loading, error, data, refetch} = useQuery(gqlGetStatements, {
		variables: {
			first: ITEM_PER_PAGE,
			skip: (page - 1) * ITEM_PER_PAGE,
			filter: filter
		}
	});

	// const [uploadFile, {loading: uploadFileLoading}
	// ] = useMutation<{ uploadFile: any }, MutationUploadFileArgs>(
	// 	gqlUploadFile
	// );

	const [deleteFile] = useMutation<{ deleteFile: String }, MutationDeleteFileArgs>(
		gqlDeleteFile
	);

	useEffect(() => {
		if (error) console.warn('>> Users useEffect error', error);
	}, [error]);

	useEffect(() => {
		refetch().then();
	}, [page]); // eslint-disable-line react-hooks/exhaustive-deps

	const formatFileSize = (size: number) => {
		const sizeKb = Math.floor(size / 1024);
		return sizeKb ? `${sizeKb} Kb` : '< 1 Kb';
	};

	useEffect(() => {
		if (loading || !data) return;

		if (data.getStatements && data.getStatements.count !== undefined) {
			const totalCnt = data.getStatements.count;
			setPageCnt(Math.floor(totalCnt / ITEM_PER_PAGE) + (totalCnt % ITEM_PER_PAGE > 0 ? 1 : 0));

			if (totalCnt) {
				setItems(data.getStatements.statements.map((el: any) => {
					return {
						id: el.fileId,
						date: formatDate(el.created),
						name: el.originFileNameWithoutExtension,
						type: el.originExtension,
						size: formatFileSize(el.fileSize),
						_fileName: el.url
					};
				}));
			} else {
				setItems([]);
			}
		}
	}, [data]); // eslint-disable-line react-hooks/exhaustive-deps

	const onPageChangeHandler = (event: any, value: number) => {
		setPage(value);
	};

	// const uploadClickHandler = async () => {
	// 	let errMessage = '';
	// 	try {
	// 		const res = await uploadFile({
	// 			variables: {
	// 				// upload: file.info
	// 				uploadParams: {
	// 					// linkedId: String
	// 					// order: Int
	// 					reason: FileReason.Statement,
	// 					upload: file.info
	// 				}
	// 			}
	// 		});
	// 		if (res.data.uploadFile.fileId) {
	// 			fileRef.current.clear();
	// 			refetch().then();
	// 			return;
	// 		}
	// 	} catch (error: any) {
	// 		try {
	// 			const errorCode = error.graphQLErrors[0].extensions.code;
	// 			if (errorCode === 'auth.access_denied') {
	// 				errMessage = 'Invalid credentials';
	// 			}
	// 		} catch (ignored) {
	// 		}
	// 	}
	//
	// 	if (!errMessage) errMessage = 'Unknown error';
	// 	console.log('itemSaveHandler errMessage:', errMessage);
	// };

	const upload = () => {
		let formData = new FormData();
		formData.append("reason", FileReason.Statement);
		formData.append("file", file.info);
		formData.append("fileData", JSON.stringify({"type": FileReason.Statement}));
		return restContext.upload('rest/api/addStatement', formData);
	};

	const uploadClickHandler = async () => {
		let errMessage = '';
		try {
			const uploadRes = await upload();

			if (uploadRes && uploadRes.status && uploadRes.status === 200) {
				fileRef.current.clear();
				refetch().then();
				enqueueSnackbar('Statement is uploaded', {variant: 'success'});
				return;
			} else {
				errMessage = 'Process error. Please try again later';
			}
		} catch (error: any) {
			try {
				const errorCode = error.graphQLErrors[0].extensions.code;
				if (errorCode === 'auth.access_denied') {
					errMessage = 'Access denied';
				} else if (errorCode === 'user.kyc_data_invalid') {
					errMessage = 'Invalid KYC data';
				}
				if (!errMessage && error.graphQLErrors[0].message) errMessage = error.graphQLErrors[0].message;
			} catch (ignored) {
			}
		}

		if (!errMessage) errMessage = 'Unknown error';
		enqueueSnackbar(errMessage, {variant: 'error'});
	};

	const deleteClickHandler = async (item: any) => {
		let errMessage = '';
		try {
			const res = await deleteFile({
				variables: {
					fileId: item.id
				}
			});
			if (res.data.deleteFile) {
				refetch().then();
				return;
			}
		} catch (error: any) {
			try {
				const errorCode = error.graphQLErrors[0].extensions.code;
				if (errorCode === 'auth.access_denied') errMessage = 'Access denied';
				if (!errMessage && error.graphQLErrors[0].message) errMessage = error.graphQLErrors[0].message;
			} catch (ignored) {
			}
		}

		if (!errMessage) errMessage = 'Unknown error';

		console.warn('deleteItemHandler errMessage:', errMessage);
	};

	const searchHandler = (val: string) => {
		if (loading) return;

		setPage(1);
		setFilter(val);
	};

	return (
		<PageWrap>
			<Row style={{marginBottom: '24px'}}>
				<Typography variant={"h3"}>
					Daily Statements
				</Typography>
			</Row>
			<UploadRow>
				<SimpleFileUploader
					ref={fileRef}
					errText={fileErr}
					variant={'outlined'}
					label={'Browse file'}
					onChange={e => handleFileChange(e, setFile, setFileErr, {maxSize: 5 * 1024 * 1024})}
				/>

				<Button
					variant="contained"
					color="primary"
					size={"large"}
					style={{minWidth: '120px', marginLeft: '24px'}}
					onClick={uploadClickHandler}
					// disabled={!file || uploadFileLoading}
					disabled={!file}
				>
					Upload
				</Button>
			</UploadRow>

			<Grid
				headCells={headCells}
				items={items}
				// idField="bankTransactionId"
				page={page}
				pageCnt={pageCnt}
				onPageChange={onPageChangeHandler}
				actions={[
					{id: 'download'},
					{id: 'del', onAction: deleteClickHandler},
				]}
				extSearch
				extSearchValue={filter}
				onSearchApply={searchHandler}
			/>
		</PageWrap>
	);
};
